import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { OutsideClickHandler } from "react-outside-click-handler"

const Wrapper = styled.div`
    ${tw`fixed w-screen h-screen overflow-hidden flex items-center justify-center top-0 left-0`};

    z-index: 99999;
    perspective: 3000px;

    // &::before {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     z-index: 2;
    //     height: 100%;
    //     width: 100%;
    //     background: hsla(231, 25%, 15%, 0.5);
    //     filter: blur(5px);
    //     -webkit-filter: blur(5px);
    // }
`

const DialogWrapper = styled(motion.div)`
    ${tw`bg-gray-800 rounded-2rem mx-auto absolute inset-y-auto inset-x-auto z-10 text-gray-300 overflow-hidden`};
    // height: 62vh;
    width: 100vw;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);

    @media (min-width: 768px) {
        width: 62vw;
    }
`

const BlurLayer = styled(motion.div)`
    ${tw`absolute top-0 left-0 h-full w-full z-0`}
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
`

const Content = styled.div`
    ${tw`relative z-20`}
`

const BlobOne = styled.img`
    ${tw`absolute z-0`};
    top: -60%;
    left: -45%;
    mix-blend-mode: overlay;
`

const BlobTwo = styled.img`
    ${tw`absolute z-0`};
    mix-blend-mode: multiply;
    bottom: -60%;
    right: -35%;
`

const OverlayModa = (props) => {
    return (
        <AnimatePresence>
            {props.isActive && (
                <Wrapper {...props}>
                    <BlurLayer
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => props.onClose()}
                    />
                    {/* <OutsideClickHandler
                        onOutsideClick={() => alert("Trigger close")}> */}
                    <DialogWrapper
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}>
                        <Content>{props.children}</Content>
                        <BlobOne src={require("../../images/blob-1.svg")} />
                        <BlobTwo src={require("../../images/blob-2.svg")} />
                    </DialogWrapper>
                    {/* </OutsideClickHandler> */}
                </Wrapper>
            )}
        </AnimatePresence>
    )
}

export default OverlayModa

OverlayModa.defaultProps = {
    onClose: () => alert("Trigger onClose"),
}
