import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import Row from "./layouts/Row"

const Wrapper = styled.div`
    ${tw`fixed top-0 right-0 w-screen z-50 bg-gray-700 py-8 h-16`}
`

const CookiesToast = () => {
    return (
        <Wrapper>
            <Row>
                <p css={tw`text-gray-100`}>
                    We use cookies to get some data for understanding certain
                    things.
                </p>
            </Row>
        </Wrapper>
    )
}

export default CookiesToast
