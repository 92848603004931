import React from "react"
import tw from "twin.macro"
import styled from "styled-components"

import {
    TwitterIcon,
    LinkedinIcon,
    InstagramIcon,
} from "../../images/icons/SocialIcons"

const Wrapper = styled.div`
    ${tw`p-16 h-full flex flex-col justify-center`}
    p {
        ${tw`text-gray-300`}
    }
`
const Number = styled.h2`
    ${tw`text-6xl font-semibold tracking-tight text-white my-0 -ml-1`}
`
const Title = styled.h3`
    ${tw`text-3xl text-white font-medium tracking-tighter capitalize my-0`};
`

const SuccessConfirmation = (props) => {
    return (
        <Wrapper>
            <Title>Welcome to the #First100Sidebrainer family!</Title>

            <p css={tw`mt-12`}>
                We are very bullish on the idea of healthy productivity, and we
                promise to go miles to deliver the experience that you deserve!
            </p>

            {/* <a
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                className="twitter-share-button"
                data-text="I just signed up to be on the waitlist for @getsidebrain"
                dataShowCount="false"></a>
            <script
                async
                src="https://platform.twitter.com/widgets.js"
                charset="utf-8"></script> */}
            <p>
                For now, you amazing human being, go share this with the world!
            </p>

            <a
                css={tw`w-auto mt-12 bg-coral-700 text-white p-4 rounded-1rem`}
                href="https://ctt.ac/v17ce"
                target="_blank">
                <div css={tw`flex items-center space-x-2`}>
                    <TwitterIcon css={tw`mr-2`} /> Tweet this
                </div>
            </a>
        </Wrapper>
    )
}

export default SuccessConfirmation
