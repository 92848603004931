import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import useDarkMode from "../utils/useDarkMode"
import { OutsideClickHandler } from "react-outside-click-handler"

import Row from "../components/layouts/Row"
import Main from "../components/layouts/Main"
import Image from "../components/Image"
import SEO from "../components/Seo"
import Header from "../components/layouts/Header"
import OverlayModal from "../components/layouts/OverlayModal"

import addToMailchimp from "gatsby-plugin-mailchimp"
import Recaptcha from "react-google-invisible-recaptcha"
import CookiesToast from "../components/CookiesToast"
import Footer from "../components/layouts/Footer"
import SuccessConfirmation from "../components/molecules/SuccessConfirmation"

import MobileMockup from "../images/hero-mockup-mobile.png"
import MobileMockupDark from "../images/hero-mockup-mobile-dark.png"

const Heading = styled.h1`
    ${tw`text-6xl font-bold text-gray-700`};
`

const Title = styled.h1`
    ${tw`text-3xl md:text-2gr font-primary font-extrabold dark:text-white text-gray-700 tracking-tight`}
    span {
        // fill: var(--pop-gradient);
        background: var(--pop-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`
const InputContainer = styled.div`
    ${tw`w-full dark:bg-gray-700 bg-opacity-50 bg-white rounded-1rem flex items-center p-2 -ml-2`};
    box-shadow: 0px 6px 80px rgba(0, 0, 0, 0.03),
        0px 2.50666px 33.4221px rgba(0, 0, 0, 0.0215656),
        0px 1.34018px 17.869px rgba(0, 0, 0, 0.0178832),
        0px 0.751293px 10.0172px rgba(0, 0, 0, 0.015),
        0px 0.399006px 5.32008px rgba(0, 0, 0, 0.0121168),
        0px 0.166035px 2.21381px rgba(0, 0, 0, 0.00843437);
`
const Button = styled.button`
    ${tw`appearance-none rounded-1rem bg-coral text-white text-center py-4 px-4 font-bold cursor-pointer`};
    background-image: var(--pop-gradient);
    box-shadow: 0px 4px 14px -3px rgba(154, 94, 106, 0.4);
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
        transform: scale(1.05) translate3d(0, -1px, 1px);
        box-shadow: 0px 2px 14px 0px rgba(154, 94, 106, 0.4);
    }
`
const Section = styled.section`
    ${tw`w-full relative z-10 rounded-b-2rem bg-white dark:bg-black`};
    // background-image: url(${require("../images/gradient-tinge.png")});
    // background-size: 125% 125%;
    // background-position: 50% 50%;
    // background-repeat: no-repeat;

    
`
const HeroMockup = styled.img`
    filter: drop-shadow(
        -1rem 2rem 2rem hsla(var(--primary-hue), 10%, 50%, 0.17)
    );
    transform: scale(0.8) translate3d(2rem, 0, 1px);
    ${tw`-mr-8`};
    // height: 80%;
    // width: auto
`
const SignupForm = styled.form`
    ${tw`relative mt-0 lg:mt-8`};
`

const MobileHeroImage = styled.img`
    ${tw`absolute z-0 top-0 right-0 block lg:hidden`};
    // transform: scale(0.25);
    height: 50%;
`

const IndexPage = () => {
    const [email, setEmail] = useState("")
    const [signupSuccessful, setSignupSuccessful] = useState(false)
    const [signupFailed, setSignupFailed] = useState(false)
    const [signupResponse, setSignupResponse] = useState("")

    const recaptcha = useRef(null)

    const onSubmit = (e) => {
        e.preventDefault()
        recaptcha.current.execute()
    }

    const onResolved = () => {
        addToMailchimp(recaptcha.current.props.email)
            .then((data) => {
                debugger
                if (data.result == "error") {
                    // alert(`Sry ${data.msg.split("<")[0]}`)
                    setSignupResponse(data.msg.split("<")[0])
                    setSignupFailed(true)
                } else if (data.result == "success") {
                    console.log("success")
                    setSignupSuccessful(true)
                }
                // alert(data.msg.split("<")[0])
                console.log(`Signup success: ${data.msg.split("<")[0]}`)
                recaptcha.current.reset()
            })
            .catch((err) => {
                // alert(`Something's not right ${err}`)
                setSignupResponse(`Something's not right ${err}`)
                setSignupFailed(true)
                console.log(`Signup error: ${err}`)
                recaptcha.current.reset()
            })
    }

    const isDesktop = useMediaQuery({
        query: "(min-device-width: 1024px)",
    })

    const isDarkMode = useDarkMode()

    return (
        <Main>
            {/* <CookiesToast/> */}
            <Header />
            <SEO />
            {/* <div></div> */}
            <Section>
                <Row
                    css={tw`justify-center relative z-10 min-h-screen pt-16 md:pt-0`}>
                    <div css={tw`w-full lg:w-1/2`}>
                        <div>
                            <Title>
                                Tune into a <br /> <span>blissful</span>{" "}
                                productive life <br />
                            </Title>
                            <p
                                css={tw`text-sm md:text-base font-primary font-medium tracking-wide dark:text-gray-200 text-gray-600`}>
                                Every other productivity app assumes that the
                                human mind can work like a machine – they cause
                                more stress and distraction than they help with
                                productivity. Sidebrain acts as an accessory to
                                your consciousness that not only makes you
                                productive but also introduces a zen-like calm
                                into your life.
                                {/* Every other productivity app is built assuming that the human mind can work like a machine. Sidebrain acts as an accessory to your consciousness that not only makes you productive, but also introduces a zen-like calm into your life. */}
                                {/* Every other productivity app is built assuming that the human mind can work like a machine. They cause more stress and distractions and don't really make you productive. Sidebrain acts as an accessory to your consciousness developing on the natural way our brain operates, and introduces a slightly opinionated framework to help fill the gaps of our mind. that not only makes you productive, but also introduces a zen-like calm into your life. */}
                            </p>
                        </div>

                        <SignupForm onSubmit={(e) => onSubmit(e)}>
                            <label
                                htmlFor="email"
                                css={tw`ml-2 mb-1 font-medium text-gray-600 dark:text-gray-200 text-lg`}>
                                Signup for Early Access
                            </label>
                            <InputContainer>
                                <input
                                    type="email"
                                    name="email"
                                    css={tw`flex-1 bg-transparent h-full p-4 text-gray-300 outline-none`}
                                    placeholder="youremail@something.com"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                                <Button type="submit">
                                    {isDesktop ? "Get it First" : "Go"}
                                </Button>

                                <Recaptcha
                                    email={email}
                                    ref={recaptcha}
                                    sitekey={
                                        process.env.GATSBY_RECAPTCHA_SITE_KEY
                                    }
                                    onResolved={() => onResolved()}
                                />
                            </InputContainer>
                        </SignupForm>
                    </div>
                    {/* Mobile Shot */}
                    <div
                        css={`
                            perspective: 3000px;
                            ${tw`hidden lg:block w-full md:w-1/2 lg:w-1/2`}
                        `}>
                        <HeroMockup
                            src={require("../images/mockups/Streams@final.png")}
                            alt="Sidebrain App"
                        />
                    </div>
                </Row>
                <MobileHeroImage
                    src={isDarkMode ? MobileMockupDark : MobileMockup}
                    alt="Sidebrain App"
                />
            </Section>
            <Footer />

            <OverlayModal
                isActive={signupFailed}
                onClose={() => setSignupFailed(false)}>
                {/* <SuccessConfirmation /> */}
                <div css={tw`p-8 md:p-12`}>
                    <h1>Sorry!</h1>
                    <p>{signupResponse}</p>
                </div>
            </OverlayModal>
            <OverlayModal
                isActive={signupSuccessful}
                // isActive={true}
                onClose={() => setSignupSuccessful(false)}>
                <SuccessConfirmation />
            </OverlayModal>
        </Main>
    )
}

export default IndexPage
